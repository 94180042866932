<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Comprobante - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Comprobante: </label>
                    <b-form-input class="text-center" readonly :value="CodeInvoice(payment.voucher_type)"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Serie: </label>
                    <b-form-input class="text-center" readonly :value="payment.serie  + ' - '+ payment.number"></b-form-input>
                  </b-form-group>
                </b-col>
          

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Tipo Operación: </label>
                    <b-form-select disabled v-model="payment.operation_type" :options="operation_type"></b-form-select>
                    <small v-if="errors.operation_type" class="form-text text-danger" >Seleccione una tipo</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Fecha Emision: </label>
                    <b-form-input class="text-center" readonly v-model="payment.broadcast_date" type="text"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: <span v-if="bnt_fees_collected" class="badge badge-primary link" @click="ModalFeedCollected">Cuotas</span></label>
                    <b-form-select @change="BntFeesCollected"  v-model="payment.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione ua forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Moneda: </label>
                    <b-form-select v-model="payment.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                  </b-form-group>
                </b-col>

                 <b-col v-if="payment.voucher_type != '07'" md="6">
                  <b-form-group>
                    <label class="control-label">Cliente: </label>
                    <input class="form-control" :value="payment.client" readonly />
                  </b-form-group>
                </b-col>

                <b-col v-if="payment.voucher_type != '07'" md="6">
                  <b-form-group>
                    <label class="control-label">Dirección: </label>
                    <b-form-input v-model="payment.address" type="text"></b-form-input>                    
                  </b-form-group>
                </b-col>

                <b-col v-if="payment.voucher_type == '07'" sm="12" md="9">
                  <b-form-group>
                    <label class="control-label">Cliente:</label>
                    <b-form-input disabled v-model="payment.client"></b-form-input>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col v-if="payment.voucher_type == '07'" md="3">
                  <b-form-group label="Motivo :">
                     <b-form-select v-model="payment.modified_reason" :options="reason"></b-form-select>
                    <small v-if="errors.reason" class="form-text text-danger">Ingrese un motivo</small>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                    <div class="table-responsive mt-3">
                      <table class="table table-striped table-hover table-bordered table-lg mt-lg mb-0">
                        <thead>
                          <tr>
                            <th width="5%" class="text-center">#</th>
                            <th width="10%" class="text-center">Codigo</th>
                            <th width="52%" class="text-center">Nombre</th>
                            <th width="7%" class="text-center">Cantidad</th>
                            <th width="10%" class="text-center">P. Unit</th>
                            <th width="10%" class="text-center">P. Total</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in payment.payment_detail" :key="index">
                          <tr>
                              <td class="text-center">{{ index + 1 }}</td>
                              <td>
                                <input :disabled="payment.voucher_type == '07'" type="text" class="form-control text-center" v-model="item.code">
                              </td>
                              <td>
                                 <input :disabled="payment.voucher_type == '07'" type="text" class="form-control text-left" v-model="item.name">
                              </td>
                              <td>
                                 <input :disabled="payment.voucher_type == '07'" type="number" @change="UpdateDetail(index)" class="form-control text-center" v-model="item.quantity">
                              </td>
                              <td>
                                 <input :disabled="payment.voucher_type == '07'" type="number" @change="UpdateDetail(index)" step="any" class="form-control text-right" v-model="item.unit_price">
                              </td>
                              <td>
                                 <input readonly type="number" step="any" class="form-control text-right" v-model="item.total_price">
                              </td>
                    
                       
                          </tr>
                              
                        </tbody>
                      </table>
                    </div>
                    <small v-if="errors.total" class="form-text text-center text-danger" >Ingrese un monto</small>
                </b-col>

                <b-col class="mt-4" md="12"> </b-col>

                <b-col md="8">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-input readonly v-model="payment.number_to_letters" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <div class="table-responsive mt-1">
                          <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                            <thead>
                              <tr>
                                <th width="25%" class="text-center">Fecha</th>
                                <th width="75%" class="text-center">Referencia</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, it) in payment.linkages" :key="it">
                              <tr>
                                  <td class="align-middle text-center">{{ item.broadcast_date }}</td>
                                  <td class="align-middle text-center">{{ item.reference }} {{payment.modified_support}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Observación:">
                        <b-form-textarea rows="1"  v-model="payment.observation" max-rows="2"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

  
                <b-col md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Subtotal :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.subtotal" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6" :label="'Igv ('+ payment.igv_percentage +'%):'" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.igv" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Total :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.total" readonly ></b-form-input>
                  </b-form-group>

                </b-col>

                

                <b-col md="3"></b-col>
                <b-col md="3">
                  <b-button type="button" @click="PrintPdf" class="form-control btn-warning text-white"  >IMPRIMIR</b-button>
                </b-col>
                <b-col md="3">
                  <b-button type="submit" class="form-control btn-primary text-white"  >GUARDAR</b-button>
                </b-col>

              </b-row>
            </b-form>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


<b-modal size="md" id="modal_fees_collected" hide-footer v-model="modal_fees_collected" class="w-100" title="CUOTAS">
      <b-form @submit.prevent="AddFeedCollected">
        <b-row>
          <b-col md="5">
            <b-form-group label="Fecha">
              <b-form-input class="text-center" :max="fee_collected.max_date" :min="fee_collected.min_date" v-model="fee_collected.date" type="date"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Monto">
              <b-form-input class="text-right" v-model="fee_collected.total" type="number" step="any"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label=".">
              <b-button type="submit" class="form-control" variant="info"><i class="fas fa-plus-circle"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>


             <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="40%" class="text-center">Fecha</th>
                    <th width="45%" class="text-center">Total</th>
                    <th width="10%" class="text-center"></th>
                  </tr>
                </thead>
  
                <tbody v-for="(item, it) in fees_collected" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-center">
                       <b-button @click="DeleteFeedCollected(it)" type="button" class="form-control" variant="danger"><i class="fas fa-trash"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

      </b-form>
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<style>
.table-responsive{
  min-height: 10px !important;
}
</style>
<script>
///import vue select
// import vSelect from "vue-select";
// import 'vue-select/dist/vue-select.css';
// import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import CodeToName from "@/assets/js/CodeToName";
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import converter from "@/assets/js/NumberToLetters";
import LoadingComponent from './../pages/Loading'
export default {
  name: "PaymentEdit",
  props: ["id_payment"],
  components:{
    LoadingComponent
  },
  data() {
    return {
      isLoading: false,
      module: 'Payment',
      role: 3,
      fees_collected: [],
        fee_collected: {
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          total: (0).toFixed(2),
          min_date:moment(new Date()).local().format("YYYY-MM-DD"),
          max_date:moment(new Date()).local().format("YYYY-MM-DD"),
        },
      modal_fees_collected:false,
      bnt_fees_collected: false,
      payment: {

      way_to_pay:'', 

      id_payment:'',
      id_room_control:'',
      id_client:'',
      id_user:'',
      id_serie:'',
      voucher_type:'',
      operation_type:'',
      serie:'',
      number:'',
      broadcast_date:'',
      broadcast_time:'',
      expiration_date:'',
      coin:'',
      payment_type:'',
      payment_method:'',
      payment_deadline:'',
      observation:'',
      modified_voucher_type:'',
      modified_serie:'',
      modified_number:'',
      modified_broadcast_date:'',
      modified_reason:'',
      modified_support:'',
      sunat_message:'',
      hash_cpe:'',
      hash_cdr:'',
      taxed_operation:'',
      exonerated_operation:'',
      unaffected_operation:'',
      export_operation:'',
      discount:'',
      subtotal:'',
      igv:'',
      total:'',
      number_to_letters:'',
      cash_payment:'',
      card_payment:'',
      web_way_payment:'',
      state:'',
      registration_date:'',
      igv_percentage:0,

      payment_detail: [],

      },

      reason:[
          {value:'01',text:'Anulación de la operación'},
          {value:'02',text:'Anulación por error en el ruc'},
          {value:'06',text:'Devolución total'},
          // {value:'07',text:'Devolucion por item'},
        ],
      


      way_to_pay:[
          {value:'01-008',text:'Contado - Efectivo'},
          {value:'01-001',text:'Contado - Depósito en Cuenta'},
          {value:'01-002',text:'Contado - Giro'},
          {value:'01-003',text:'Contado - Transferencia de Fondos'},
          {value:'01-004',text:'Contado - Orden de Pago'},
          {value:'01-005',text:'Contado - Tarjeta de Débito'},
          {value:'03-7',text:'Credito - 7 Dias'},
          {value:'03-15',text:'Credito - 15 Dias'},
          {value:'03-30',text:'Credito - 30 Dias'},
          {value:'03-45',text:'Credito - 45 Dias'},
          {value:'03-60',text:'Credito - 60 Dias'},
          {value:'03-75',text:'Credito - 75 Dias'},
          {value:'03-90',text:'Credito - 75 Dias'},
      ],
      coins:[
        {value: 'PEN', text:'PEN'},
        {value: 'USD', text:'USD'},
        {value: 'CLP', text:'CLP'},
      ],
      operation_type: [
        {value: '01', text:'Venta Interna'},
        {value: '02', text:'Exportación'},
      ],
      //errors
      errors: {
        way_to_pay: false,
        coin:false,
        operation_type:false,
        total:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewPayment();
 
  },
  methods: {
    TotalPrices,
    CodeInvoice,

    ViewPayment,
    EditPayment,
    Validate,
    UpdateDetail,

    BntFeesCollected,
    ModalFeedCollected,
    AddFeedCollected,
    DeleteFeedCollected,

    PrintPdf,

    ...mapActions('Shopping',['mLoadResetShoppingDetail']),
    ...mapActions('Shopping',['mLoadAddShoppingDetail']),
    ...mapActions('Shopping',['mLoadTotalsShoppingDetail']),
    
  },

  computed: {
    ...mapState('Shopping',['shopping_detail','total_shopping']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function PrintPdf() {
  
    let id_payment = je.decrypt(this.id_payment);
    let url = this.url_base + "payment-pdf/" + id_payment;
    window.open(url, '_blank');
}

function CodeInvoice(code) {
  return CodeToName.NameInvoice(code);
}

function ViewPayment() {

  let id_payment = je.decrypt(this.id_payment);
  let me = this;
  let url = this.url_base + "payment/view/" + id_payment;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.payment.igv_percentage = response.data.result.payment.igv_percentage;
          me.payment.id_payment = response.data.result.payment.id_payment;
          me.payment.id_room_control = response.data.result.payment.id_room_control;
          me.payment.id_client = response.data.result.payment.id_client;
          me.payment.id_user = response.data.result.payment.id_user;
          me.payment.id_serie = response.data.result.payment.id_serie;
          me.payment.operation_type = response.data.result.payment.operation_type;
          me.payment.voucher_type = response.data.result.payment.voucher_type;
          me.payment.serie = response.data.result.payment.serie;
          me.payment.number = response.data.result.payment.number;
          me.payment.broadcast_date = response.data.result.payment.broadcast_date;
          me.payment.broadcast_time = response.data.result.payment.broadcast_time;
          me.payment.expiration_date = response.data.result.payment.expiration_date;
          me.payment.coin = response.data.result.payment.coin;
          me.payment.payment_type = response.data.result.payment.payment_type;
          me.payment.payment_method = response.data.result.payment.payment_method;
          me.payment.payment_deadline = response.data.result.payment.payment_deadline;
          me.payment.observation = response.data.result.payment.observation;
          me.payment.modified_voucher_type = response.data.result.payment.modified_voucher_type;
          me.payment.modified_serie = response.data.result.payment.modified_serie;
          me.payment.modified_number = response.data.result.payment.modified_number;
          me.payment.modified_broadcast_date = response.data.result.payment.modified_broadcast_date;
          me.payment.modified_reason = response.data.result.payment.modified_reason;
          me.payment.modified_support = response.data.result.payment.modified_support;
          me.payment.sunat_message = response.data.result.payment.sunat_message;
          me.payment.hash_cpe = response.data.result.payment.hash_cpe;
          me.payment.hash_cdr = response.data.result.payment.hash_cdr;
          me.payment.taxed_operation = response.data.result.payment.taxed_operation;
          me.payment.exonerated_operation = response.data.result.payment.exonerated_operation;
          me.payment.unaffected_operation = response.data.result.payment.unaffected_operation;
          me.payment.export_operation = response.data.result.payment.export_operation;
          me.payment.discount = response.data.result.payment.discount;
          me.payment.subtotal = response.data.result.payment.subtotal;
          me.payment.igv = response.data.result.payment.igv;
          me.payment.total = response.data.result.payment.total;
          me.payment.number_to_letters = response.data.result.payment.number_to_letters;
          me.payment.cash_payment = response.data.result.payment.cash_payment;
          me.payment.card_payment = response.data.result.payment.card_payment;
          me.payment.web_way_payment = response.data.result.payment.web_way_payment;
          me.payment.state = response.data.result.payment.state;
          me.payment.registration_date = response.data.result.payment.registration_date;
          me.payment.payment_detail = response.data.result.payment_detail;
          me.payment.linkages = response.data.result.payment.linkages;

          me.bnt_fees_collected = response.data.result.payment.payment_type == "03" ? true : false;

           if (response.data.result.payment.payment_type == "01") {
            me.payment.way_to_pay = response.data.result.payment.payment_type+'-'+response.data.result.payment.payment_method;
          }
          if (response.data.result.payment.payment_type == "03") {
            me.payment.way_to_pay = response.data.result.payment.payment_type+'-'+response.data.result.payment.payment_deadline;
          }

          me.payment.client = response.data.result.payment.name + ' - '+response.data.result.payment.document_number;
          me.payment.address = response.data.result.payment.address;
          //  if (me.payment.voucher_type == "01" || me.payment.voucher_type == "03") {
          //   me.operation_type = [
          //         {value: '01', text:'Venta Interna'},
          //         {value: '02', text:'Exportación'},
          //   ];
          // }else{
          //   me.operation_type = [
          //         {value: '01', text:'Venta Interna'},
          //   ];
          // }

          me.fees_collected = response.data.result.fees_collected;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
}

function UpdateDetail(indexp) {
  let detail = this.payment.payment_detail;
  for (let index = 0; index < detail.length; index++) {
    if (indexp == index) {
        detail[index].quantity = detail[index].quantity.length == 0 ? 1 : detail[index].quantity;
        detail[index].unit_price = detail[index].unit_price.length == 0 ? 0 : detail[index].unit_price;

        detail[index].quantity = parseFloat(detail[index].quantity);
        detail[index].unit_price = parseFloat(detail[index].unit_price);
        detail[index].total_price = detail[index].quantity * detail[index].unit_price;

        detail[index].unit_price = detail[index].unit_price.toFixed(2);
        detail[index].total_price = detail[index].total_price.toFixed(2);

        this.TotalPrices();
        break;
    }
  }
}


function TotalPrices() {
  console.log("total price")
  let detail = this.payment.payment_detail;
  this.payment.taxed_operation = 0;
  this.payment.exonerated_operation = 0;
  this.payment.unaffected_operation = 0;
  this.payment.export_operation = 0;
  this.payment.discount = 0;
  this.payment.subtotal = 0;
  this.payment.igv = 0;
  this.payment.total = 0;

  let taxed_operation = 0;
  let exonerated_operation = 0;
  let unaffected_operation = 0;
  let export_operation = 0;
  let igv_percentage = parseFloat(this.payment.igv_percentage/100);
  igv_percentage  = 1 + igv_percentage

  for (let index = 0; index < detail.length; index++) {
    if (detail[index].igv == '10') {
      taxed_operation += parseFloat(detail[index].total_price);
    }
    if (detail[index].igv == '20') {
      exonerated_operation += parseFloat(detail[index].total_price);
    }
    if (detail[index].igv == '30') {
      unaffected_operation += parseFloat(detail[index].total_price);
    }
    if (detail[index].igv == '40') {
      export_operation += parseFloat(detail[index].total_price);
    }
  }

  this.payment.igv = taxed_operation - (taxed_operation / igv_percentage);
  this.payment.total = (taxed_operation + exonerated_operation + unaffected_operation + export_operation);
  this.payment.subtotal = (this.payment.total  - this.payment.igv);
  

  this.payment.taxed_operation = (taxed_operation / igv_percentage).toFixed(2);
  this.payment.exonerated_operation = exonerated_operation.toFixed(2);
  this.payment.unaffected_operation = unaffected_operation.toFixed(2);
  this.payment.export_operation = export_operation.toFixed(2);
  this.payment.discount = this.payment.discount.toFixed(2);
  this.payment.subtotal = this.payment.subtotal.toFixed(2);
  this.payment.igv = this.payment.igv.toFixed(2);
  this.payment.total = this.payment.total.toFixed(2);

  this.payment.number_to_letters =  'SON: '+ converter.NumberToLetters(this.payment.total);

}



function EditPayment(mthis) {

  let me = mthis;
  let url = me.url_base + "payment/edit";

  me.payment.fees_collected = me.fees_collected;
  let data = me.payment;
 me.isLoading = true;
  axios({
    method: "put",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          Swal.fire({ icon: 'success', text: 'Se ha editado el pago', timer: 2000,})
      }else if(response.data.status == 400){
          Swal.fire({ icon: 'error', text: 'No se puede editar el pago', timer: 2000,})
      } else {
        Swal.fire({ icon: 'error',text: 'A ocurrido un error', timer: 2000})
      }
       me.isLoading = false;
    })

}

function Validate() {

  this.errors.operation_type = this.payment.operation_type.length == 0 ? true : false;
  this.errors.way_to_pay = this.payment.way_to_pay.length == 0  ? true : false;
  this.errors.coin = this.payment.coin.length == 0 ? true : false;
  this.errors.total = parseFloat(this.payment.total) == 0 ? true : false;
  

  if (this.errors.operation_type == true) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay == true) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.coin == true) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.total == true) { this.validate = true; return false;}else{ this.validate = false; }
  



  
  let me = this;

  Swal.fire({
    title: 'Esta seguro de editar el registro?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      EditPayment(me);
    }
  })


}




// CUOTAS DE PAGO
function BntFeesCollected() {
  let payment_type = this.payment.way_to_pay.split('-');
  let days = 0;
  if (payment_type[0] == "03") {
    this.bnt_fees_collected = true;
    days = payment_type[1];
  }else{
    this.bnt_fees_collected = false;
  }
}

function ModalFeedCollected() {
  this.modal_fees_collected = true;
  let payment_type = this.payment.way_to_pay.split('-');
  let days = payment_type[1];
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  this.fee_collected.max_date = moment(this.fee_collected.min_date, "YYYY-MM-DD").add('days',parseInt(days)).local().format("YYYY-MM-DD");
  
}

function AddFeedCollected() {
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  balance = parseFloat(this.payment.total) - parseFloat(balance); 


  if (this.fee_collected.date.length == 0) {
    return false;
  }
  if (this.fee_collected.total.length == 0 || balance < parseFloat(this.fee_collected.total))  {
    return false;
  }
  
  this.fees_collected.push({date:this.fee_collected.date, total: parseFloat(this.fee_collected.total).toFixed(2)});

  balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  
}

function DeleteFeedCollected(index) {
  for (var i = 0; i < this.fees_collected.length; i++) {
    if (i == index) {
      this.fees_collected.splice(i, 1); break;
    }
  }

  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);
  
}


</script>
